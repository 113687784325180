import React, { useEffect, useState } from "react"
import { declensionNum } from "../services/helpers"

const Timer = ({date, style, fullDate = false, callback }) => {

	const [state, setState] = useState()
	const [timer, setTimer] = useState()
	const [full, setFull] = useState()

	useEffect(() => {
		setFull(fullDate)
		setState(date)
	}, [date, fullDate])

	useEffect(() => {
		setTimer(init())
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, full])

	useEffect(() => {
		let timerID = setTimeout(() => {
			setTimer(init())
		}, 1000)
		return () => {clearTimeout(timerID)}
	})

	function init () {
		if(state) {
			const diff = state - new Date()

			if(diff < 0) {
				setState(null)
				if(callback) callback()
			}

			return long(diff)
		}
	}

	function long (diff) {
		const { years, month, day } = recurce()
		const days = full ? day : diff > 0 ? Math.floor(diff / 1000 / 60 / 60 / 24) : 0
		const hours = diff > 0 ? Math.floor(diff / (1000 * 60 * 60)) % 24 : 0
		const minutes = diff > 0 ? Math.floor(diff / (1000 * 60)) % 60 : 0
		const seconds = diff > 0 ? Math.floor(diff / 1000) % 60 : 0

		return {
			years: full && years && { num: doubleZero(years), words: declensionNum(years, ['год', 'года', 'лет']) },
			month: full && { num: doubleZero(month), words: declensionNum(month, ['месяц', 'месяца', 'месяцев']) },
			days: { num: doubleZero(days), words: declensionNum(days, ['день', 'дня', 'дней']) },
			hours: { num: doubleZero(hours), words: declensionNum(hours, ['час', 'часа', 'часов']) },
			minutes: { num: doubleZero(minutes), words: declensionNum(minutes, ['минута', 'минуты', 'минут']) },
			seconds: { num: doubleZero(seconds), words: declensionNum(seconds, ['секунда', 'секунды', 'секунд']) }
		}
	}
	
	function doubleZero (num) {
		return num < 10 ? `0${num}` : num
	}

	const daysToMonth = (year, month) => {
		return new Date(year, month + 1, 0).getDate()
	}

	const leapYear = (year) => {
		return Number.isInteger( year / 4 ) ? 366 : 365
	}

	function recurce() {
		const current = new Date()
		const diff = Math.floor((state - current) / (1000 * 60 * 60 * 24))
		let maxYear = state.getFullYear() - 1
		let maxMonth = state.getMonth() - 1

		const recurceYears = ({years = 0, month = 0, diff}) => {
			
			const y = leapYear(maxYear)
			const m = daysToMonth(maxYear, maxMonth)
			
			if ( diff < m ) return {years, month, day: diff }

			if (diff < y) {
				maxMonth--
				return recurceYears({years, month: month + 1, diff: diff - m})
			}
			maxYear--
			return recurceYears({years: years + 1, diff: diff - y})
		}
		return recurceYears({diff})
	}

	return (
		<>
			<div className="timer__items" style={{...style}}>
				{timer && Object.values(timer).map((item, i) => {
					if ( !item ) return null
					return (
						<div key={i} className="timer__item">
							{item.num}
							<span className="timer__date">{item.words}</span>
						</div>
					)
				})}
				
			</div>
			{timer && 
				<span  onClick={() => setFull(!full)} style={{cursor:'pointer', color: '#f44336'}} >
					{full ? 'Показать в днях' : 'Показать в месяцах'}
				</span>
			}
			
		</>
	)
	
}

export default Timer