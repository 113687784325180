import { useContext, useRef } from "react"
import { useLocation } from "react-router-dom"
import AuthContext from "./auth-context"

function useAuth() {
	return useContext(AuthContext)
}

function useQuery() {
	return new URLSearchParams(useLocation().search)
}

function useFocus() {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

    return [ htmlElRef, setFocus ] 
}

export {
	useAuth,
	useQuery,
	useFocus,
}