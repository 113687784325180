import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { declensionNum, formatDate } from '../../services/helpers'
import Storage from '../../services/local-storage'
import Timer from '../timer'

const Otpusk = ({persons}) => {
	
	const { getSorage, addStorage, editStorage, deleteStorage } = Storage()
	const {userID, slug} = useParams()
	const location = useLocation()
	const [state, setState] = useState( init() )
	
	const [triger, setTriger] = useState(false)
	const callbackTriger = () => setTriger(!triger)

	useEffect(() => {
		setState( init() )
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slug, userID])

	function init () {
		if(persons) {
			return persons.filter( item => (item.slug === slug) )[0]
		}

		if(userID) {
			return getSorage(userID, 'otpusk')
		} else {
			let params = {}
			const queryParams = new URLSearchParams(location.search)
			
			for (const [key, value] of queryParams) {
				params[key] = value
			}
			params.name  = params.name ? params.name : 'Anonymous'
			return params
		}
	}

	const {name, start, end} = state

	const startObj = start && new Date(start)
	const endObj = end && new Date(end)
	
	const diffStart =  startObj - Date.now()
	const diffEnd =  endObj - Date.now()

	const text = diffStart > 0 ? 'До начала отпуска' : diffEnd > 0 ? 'До окончания отпуска' : 'Отпуск закончился :('
	const date = diffStart > 0 ? startObj : diffEnd > 0 ? endObj : ''
	
	date && date.setHours(0)
	
	if (diffStart < 0 && diffEnd > 0) {
		date.setDate(date.getDate() + 1)
	}
	
	let days = startObj && endObj ? Math.floor((endObj - startObj) / (1000 * 60 * 60 * 24) + 1) : 0
	
	return (
		<>
			<div className="timer center">
				<h1>{name}</h1>
				<span>Отпуск {days} {declensionNum(days, ['день', 'дня', 'дней'])} ( с {formatDate(start)} по {formatDate(end)} )</span>
				<h2>{text}</h2>
				<Timer date={date} style={{fontSize: '40px'}} callback={callbackTriger} fullDate={false} />
				<div className='wrap-button'>
					{!userID && !persons && <button className='button' onClick={e => addStorage(state, 'otpusk')} >Сохранить</button> }
					{userID && <button className='button' onClick={e => editStorage(state, 'otpusk')} >Редактировать</button> }
					{userID && <button className='button' onClick={e => deleteStorage(state, 'otpusk')} >Удалить</button> }
				</div>
			</div>
		</>
		
	)
}

export default Otpusk