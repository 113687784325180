import React from 'react'
import { Outlet } from 'react-router-dom'
import PersonsMenu from '../elements/persons-menu'

const LayoutMenu = ({persons}) => {
	return (
        <div className='wrap'>
            <PersonsMenu persons={persons} />
			<Outlet />
        </div>
    )
}

export default LayoutMenu