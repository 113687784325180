import React from 'react'

const Home = () => {	
	return (
		<div className='timer'>
			<h1 className='center'>Таймер отпусков</h1>
			<p>Указав дату начала и окончания отпуска, вы сможете наблюдать за таймером обратного отсчета до начала или окончания отпуска.</p>
			<p>Так же указав дату рождения узнаете сколько осталось до пенсии.</p>
		</div>
	)
}

export default Home