import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from './hooks'

const RequireAuth = ({children}) => {
	const {isAuth} = useAuth()
	const location = useLocation()

	if (!isAuth) {
		return <Navigate to="/login" state={{ from: location }} replace />
	}
	
	return children
}

export default RequireAuth