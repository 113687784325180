import React, { useState } from "react"
import AuthContext from "./auth-context"

const AuthProvider = ({ children }) => {
	const [isAuth, setIsAuth] = useState(localStorage.getItem('user'))
	const [triger, setTriger] = useState(true)

	const signin = (pass, callback) => {
		if (pass === '111') {
			setIsAuth(true)
			localStorage.setItem('user', true)
			return callback()
		}
		return false
	}

	const signout = (callback) => {
		setIsAuth(false)
		localStorage.removeItem('user')
		return callback()
	}

	const trigerUpdate = () => {
		setTriger(!triger)
		return triger
	}

	const value = { isAuth, signin, signout, trigerUpdate }

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
export default AuthProvider