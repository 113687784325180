import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useAuth } from '../../services/hooks'

const PersonsMenu = ({persons}) => {
	const {isAuth, trigerUpdate} = useAuth()
	const {pathname} = useLocation()
	
	const [state, setState] = useState( init() )

	useEffect(() => {
		setState( init() )
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname, trigerUpdate])
	
	function init () {
		const path = pathname.split('/')
		if(path[1] === 'otpusk') return JSON.parse( localStorage.getItem('users-otpusk') )
		if(path[1] === 'pension') return JSON.parse( localStorage.getItem('users-pension') )
	}
	
	if(isAuth) {
		return (
			<div className='mainmenu'>
				<ul>
					{persons.map(({ name, slug }) => (
						<li key={slug}>
							<NavLink to={slug}>{name}</NavLink>
						</li>
					))}
				</ul>
			</div>
		)
	}
	if(state) {
		return (
			<div className='mainmenu'>
				<ul>
					{state.map(({ name, id }) => (
						<li key={id}>
							<NavLink to={`timer/${id.toString()}`}>{name}</NavLink>
						</li>
					))}
				</ul>
			</div>
		)
	}
}

export default PersonsMenu