import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { formatDate } from '../../services/helpers'
import Timer from '../timer'
import Storage from '../../services/local-storage'

const Pension = ({persons}) => {
	
	const { getSorage, addStorage, editStorage, deleteStorage } = Storage()
	const {userID, slug} = useParams()
	const location = useLocation()

	const [state, setState] = useState( init() )
	// const [error, setError] = useState()
	
	const [triger, setTriger] = useState(true)
	const callbackTriger = () => setTriger(!triger)

	useEffect(() => {
		setState( init() )
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slug, userID])

	function init () {
		if(persons) {
			return persons.filter( item => (item.slug === slug) )[0]
		}

		if(userID) {
			return getSorage(userID, 'pension')

		} else {
			let params = {}
			const queryParams = new URLSearchParams(location.search)
			
			for (const [key, value] of queryParams) {
				params[key] = value
			}
			params.name  = params.name ? params.name : 'Anonymous'
			return params
		}
	}
 
	const {name, date, gender, north, child} = state

	const start = new Date(date)

	// date + 60 year
	if(typeof start === 'object') {
		let num = gender === 'famale' ? 60 : 65
		num = JSON.parse(north) ? num - 5 : num
		num = gender === 'famale' && JSON.parse(north) && JSON.parse(child) ? num - 5 : num
		start.setFullYear( start.getFullYear() + num )
	}
	
	const diffStart =  start - Date.now()

	const text = diffStart > 0 ? 'До пенсии осталось' : 'Поздравляю! Вы на пенсии!'
	const start2 = diffStart > 0 ? start : ''
	start2 && start2.setHours(0)
	
	return (
		<>
			<div className="timer center">
				<h1>{name}</h1>
				<span>День рождения {formatDate(new Date(date))}</span>
				<h2>{text}</h2>
				<Timer date={start2} style={{fontSize: '40px'}} callback={callbackTriger} fullDate={true} />
				<div className='wrap-button'>
				{!userID && !persons && <button className='button' onClick={e => addStorage(state, 'pension')} >Сохранить</button> }
					{userID && <button className='button' onClick={e => editStorage(state, 'pension')} >Редактировать</button> }
					{userID && <button className='button' onClick={e => deleteStorage(state, 'pension')} >Удалить</button> }
				</div>
			</div>
		</>
		
	)
}

export default Pension