function formatDate(date) {
	if(!date || date === 'null') return 0
	const dateObj = new Date(date)
	let dd = dateObj.getDate()
	if (dd < 10) dd = '0' + dd
	let mm = dateObj.getMonth() + 1
	if (mm < 10) mm = '0' + mm
	return `${dd}.${mm}.${dateObj.getFullYear()}`
}

function declensionNum(num, words) {
	return words[(num % 100 > 4 && num % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(num % 10 < 5) ? num % 10 : 5]]
}

export {
	formatDate,
	declensionNum,
}