import React from 'react'
import {Routes, Route} from 'react-router-dom'
import './App.css'
import MainMenu from './components/elements/main-menu'
import RequireAuth from './services/require-auth'
import Home from './components/pages/home'
import FormLogin from './components/elements/form-login'
import FormOtpusk from './components/elements/form-otpusk'
import FormPension from './components/elements/form-pension'
import Otpusk from './components/pages/otpusk'
import Pension from './components/pages/pension'
import Layout from './components/layouts/layout'
import LayoutMenu from './components/layouts/layoutMenu'
import NotFound from './components/pages/not-found'

const persons = [
	{slug: 'kostrov', name: 'Костров', start: '2022-09-07', end: '2022-10-28', date: '1970-05-13', gender: 'male', north: true},
	{slug: 'misan', name: 'Мисан', start: '2022-04-01', end: '2022-05-24', date: '1970-11-12', gender: 'male', north: true},
	{slug: 'chernov', name: 'Чернов', start: '2022-05-25', end: '2022-07-16', date: '1971-03-21', gender: 'male', north: true},
	{slug: 'yartsev', name: 'Ярцев', start: '2022-07-17', end: '2022-09-06', date: '1975-11-14', gender: 'male', north: true},
]

const App = () => {
	return (
		<>
			<MainMenu/>
			<Routes>

				<Route path='/' element={<Home />} />
				{/* <Route index element={<FormOtpusk />} /> */}
				<Route path={'/login'} element={<FormLogin />} />

				<Route exact path={'/otpusk'} element={<LayoutMenu persons={persons} />} >
					<Route index element={<FormOtpusk />} />
					<Route path={'timer'} element={<Layout />} >
						<Route path="*" element={<NotFound />} />
						<Route index element={<Otpusk />} />
						<Route path={':userID/edit'} element={<FormOtpusk />} />
						<Route path={':userID'} element={<Otpusk />} />
						
					</Route>
					<Route path={':slug'} element={
						<RequireAuth>
							<Otpusk persons={persons} />
						</RequireAuth>
					} />
					<Route path="*" element={<NotFound />} />
				</Route>

				<Route path={'/pension'} element={<LayoutMenu persons={persons} />} >
					<Route index element={<FormPension />} />
					<Route path={'timer'} element={<Layout />} >
						<Route index element={<Pension />} />
						<Route path={':userID/edit'} element={<FormPension />} />
						<Route path={':userID'} element={<Pension />} />
						
					</Route>
					<Route path={':slug'} element={
						<RequireAuth>
							<Pension persons={persons} />
						</RequireAuth>
					} />
					<Route path="*" element={<NotFound />} />
				</Route>

				<Route path="*" element={<NotFound />} />

			</Routes>
		</>
	)
}

// function NotFound() {
// 	return (
// 		<div className='timer center'>
// 			<h1 style={{margin: 'auto'}}>Нет такой страницы!</h1>
// 		</div>
// 	)
// }

// const Home2 = () => {
// 	const {isAuth} = useAuth()
	
// 	return (
// 		<div className='timer center'>
// 			<h1 style={{margin: 'auto'}}>{isAuth ? 'Выберите пользователя' : 'Залогиньтесь'}</h1>
// 		</div>
// 	)
// }

export default App
