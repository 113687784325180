import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Storage from '../../services/local-storage'

const FormOtpusk = () => {
	
	const {userID} = useParams()
	const navigate = useNavigate()
	const { getSorage, saveStorage } = Storage()

	const [state, setState] = useState( init() )
	// const [error, setError] = useState()

	useEffect(() => {
		setState( init() )
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userID])

	function init () {
		if (userID) return getSorage(userID, 'otpusk')
		return {name: '', start: '', end: ''}
	}

	const onChange = ({target}) => {
		const name = target.name
		const value = target.type === 'checkbox' ? target.checked : target.value
		setState({...state, [name]: value})
	}

	function submitLook (e) {
		e.preventDefault()
		navigate( `/otpusk/timer?start=${start}&end=${end}&name=${name}` )
	}

	function submitSave (e) {
		e.preventDefault()
		saveStorage(state, 'otpusk')
	}

	const {name, start, end} = state

	const submit = userID ? submitSave : submitLook
	
	return (
		<form className='timer'onSubmit={submit}>
			<div className='form'>
				<label htmlFor="name">Имя</label>
				<input
					id="name"
					name='name'
					type="text"
					value={name}
					onChange={e => onChange(e)}
				/>
			</div>
			<div className='form'>
				<label htmlFor="date-start">Начало отпуска</label>
				<input
					id="date-start"
					name="start"
					type="date"
					value={start}
					onChange={e => onChange(e)}
				/>
			</div>
			<div className='form'>
				<label htmlFor="date-end">Окончание отпуска</label>
				<input
					id="date-end"
					name="end"
					type="date"
					value={end}
					onChange={e => onChange(e)}
				/>
			</div>
			<input className='button' type="submit" value={userID ? "Сохранить" : "Просмотр"} />
		</form>		
	)
}

export default FormOtpusk