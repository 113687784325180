import React from 'react'

function NotFound() {
	return (
		<div className='timer center'>
			<h1 style={{margin: 'auto'}}>Нет такой страницы!</h1>
		</div>
	)
}

export default NotFound