import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Storage from '../../services/local-storage'

const FormPension = () => {

	const {userID} = useParams()
	const navigate = useNavigate()
	const { getSorage, saveStorage } = Storage()

	const [state, setState] = useState( init() )
	const [error, setError] = useState()

	useEffect(() => {
		setState( init() )
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userID])

	function init () {
		if (userID) return getSorage(userID, 'pension')
		return {date: '', north: true, gender: 'male', name: '', child: false}
	}

	const onChange = ({target}) => {
		const name = target.name
		const value = target.type === 'checkbox' ? target.checked : target.value
		setState({...state, [name]: value})
	}

	function submitLook (e) {
		e.preventDefault()

		let timerId

		setError('')
		clearTimeout(timerId)

		if(state.date === null) {
			setError(<span className='error absolute' >Укажите День рождения</span>)
			timerId = setTimeout(() => {
				setError('')
			}, 2000)
			return
		}

		navigate( `/pension/timer?date=${date}&gender=${gender}&north=${north}&child=${child}&name=${name}` )
	}

	function submitSave (e) {
		e.preventDefault()
		saveStorage(state, 'pension')
	}

	const { name, date, north, gender, child } = state

	const submit = userID ? submitSave : submitLook

	return (
		<form className='timer'onSubmit={submit}>
			{error}
			
			<div className='form'>
				<label htmlFor="name">Имя</label>
				<input
					id="name"
					name='name'
					type="text"
					value={name}
					onChange={e => onChange(e)}
				/>
			</div>
			<div className='form'>
				<label htmlFor="date">День рождения</label>
				<input
					id="date"
					name="date"
					type="date"
					value={date}
					onChange={e => onChange(e)}
				/>				
			</div>

			<fieldset>
				<legend>Дополнительные параметры:</legend>

				<div>
					<input type="checkbox" id="north" name="north" 
						defaultChecked={(north || north === 'true')}
						onChange={e => onChange(e)}
					/>
					<label htmlFor="north">Северный стаж</label>
				</div>

				<div>
					<input type="radio" id="male" name="gender" value="male" 
						defaultChecked={gender === 'male'}
						onChange={e => onChange(e)}
					/>
					<label htmlFor="male">Мужчина</label>
					<input type="radio" id="famale" name="gender" value="famale"
						defaultChecked={gender === 'famale'}
						onChange={e => onChange(e)}
					/>
					<label htmlFor="famale">Женщина</label>
					{gender === 'famale' &&
					<>
						<input type="checkbox" id="child" name="child" 
							defaultChecked={(child || child === 'true')}
							onChange={e => onChange(e)}
						/>
						<label htmlFor="child">2+ детей</label>
					</>}
				</div>
			</fieldset>

			<input className='button' type="submit" value={userID ? "Сохранить" : "Просмотр"} />
		</form>
	)
}

export default FormPension