import { useNavigate } from "react-router-dom"
import { useAuth } from "./hooks"

const Storage = () => {

	const navigate = useNavigate()
	const {trigerUpdate} = useAuth()

	function getSorage (userID, to) {
		const usersTo = `users-${to}`

		const users = JSON.parse( localStorage.getItem(usersTo) )
		const user = users.filter( item => item.id === Number(userID) )[0]

		return user
	}

	function addStorage (form, to) {
		
		form.id = Date.now()
		const usersTo = `users-${to}`

		let users = JSON.parse( localStorage.getItem(usersTo) )

		if (users) {
			users.push(form)
		} else {
			users = [form]
		}
		
		localStorage.setItem(usersTo, JSON.stringify(users) )
		trigerUpdate()
		navigate( `/${to}/timer/${form.id}`, { replace: true } )
	}

	function editStorage (form, to) {
		navigate( `/${to}/timer/${form.id}/edit`, { replace: true } )
	}

	function saveStorage (form, to) {
		const {id} = form
		const usersTo = `users-${to}`

		let users = JSON.parse( localStorage.getItem(usersTo) )
		users = users.map(item => {
			return item.id === id ? form : item
		})

		localStorage.setItem( usersTo, JSON.stringify(users) )
		trigerUpdate()
		navigate( `/${to}/timer/${id}`, { replace: true } )
	}

	function deleteStorage (form, to) {
		const {id} = form
		const usersTo = `users-${to}`
		let users = JSON.parse( localStorage.getItem(usersTo) )

		users = users.filter(item => item.id !== id)
		localStorage.setItem(usersTo, JSON.stringify(users) )
		trigerUpdate()
		navigate( `/${to}`, { replace: true } )
	}

	return { getSorage, addStorage, editStorage, saveStorage, deleteStorage }
}

export default Storage