import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import {BrowserRouter as Router} from 'react-router-dom'
import AuthProvider from './services/auth-provider'
import App from './App'
import './index.css'

const root = createRoot( document.getElementById('root') )
root.render(
	<StrictMode>
		<AuthProvider>
			<Router>
				<App />
			</Router>
		</AuthProvider>
	</StrictMode>
)