import React from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../services/hooks'
import img from '../../timer.svg'

const MainMenu = () => {
	const {isAuth, signout} = useAuth()
	const {pathname} = useLocation()
	const navigate = useNavigate()

	const path = pathname ? pathname.split('/') : ''
	const slug = path[2] && path[2] !== 'timer' ? `/${path[2]}` : ''

	return (
		<div className='mainmenu'>
			
			<ul>
				<div className='logo'><Link to={'/'} ><img src={img} alt={'logo'} /></Link></div>
				<li><NavLink to={`/otpusk${slug}`} >Отпуск</NavLink></li>
				<li><NavLink to={`/pension${slug}`} >Пенсия</NavLink></li>
				{!isAuth ?
					<li><NavLink to={'/login'}>Войти</NavLink></li>
					:
					<li><a href='/logout' onClick={ e => {
						e.preventDefault()
						signout(() => navigate("/"))
					}}>Выйти</a></li>
				}
			</ul>
		</div>
	)
}

export default MainMenu