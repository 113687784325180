import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth, useFocus } from '../../services/hooks'

const FormLogin = () => {
	
	const [state, setState] = useState('')
	const [error, setError] = useState('')
	const [inputRef, setInputFocus] = useFocus()
	const {signin} = useAuth()
	const location = useLocation()
	const navigate = useNavigate()
	
	const from = location.state?.from?.pathname || '/otpusk'
	
	function submit (e) {
		e.preventDefault()
		
		let timerId

		setError('')
		clearTimeout(timerId)

		const test = signin(state, () => {
			navigate(from, { replace: true })
			return true
		})

		if (!test) {
			setError(
				<span className='error' >Неверный пароль</span>
			)
			timerId = setTimeout(() => {
				setError('')
			}, 2500)
			setState('')
			setInputFocus()
		}
		
	}

	const onPasswordChange = (e) => {
		setState(e.target.value)
	}

	return (
		<div>
			<form autoComplete="false" className='timer'>
				<input
					type="password"
					placeholder="Пароль"
					value={state}
					onChange={e => onPasswordChange(e)}
					autoComplete="off"
					inputMode="numeric"
					ref={inputRef}
					autoFocus
				/>

				<button
					className="button"
					onClick={e => submit(e)}
				>Вход</button>
				
				{error}
			</form>
			
		</div>
	)
}

export default FormLogin